import { application } from "./application"

import ThemeController from "./theme_controller"
import ConfirmController from "./confirm_controller"
import DirectUploadController from "./direct_upload_controller"
import FlashController from "./flash_controller"
import FormController from "./form_controller"
import FramesController from "./frames_controller"
import HighlightController from "./highlight_controller"
import PreviewController from "./preview_controller"
import DialogController from "./dialog_controller"
import EventsController from "./events_controller"

application.register("theme", ThemeController)
application.register("confirm", ConfirmController)
application.register("direct-upload", DirectUploadController)
application.register("flash", FlashController)
application.register("fomr", FormController)
application.register("frames", FramesController)
application.register("highlight", HighlightController)
application.register("preview", PreviewController)
application.register("dialog", DialogController)
application.register("events", EventsController)