import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.target) {
      this.add()
      this.remove()
    }
  }

  add() {
    setTimeout(() => { this.target.classList.add('highlight') }, 100)
    this.target.classList.add('transition')
    this.target.classList.add('duration-300')
  }

  remove() {
    setTimeout(() => { this.target.classList.remove('highlight') }, 700)
  }

  get target() {
    return document.getElementById(this.data.get('record-id'))
  }
}
