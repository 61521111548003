const template = document.createElement('template');
template.innerHTML = `
  <div class="bg-blue-darkest"><slot></slot></div>
`

class ModalWindow extends HTMLElement {
  constructor() {
    super()
    this.appendChild(template.content.cloneNode(true))
    console.log(template.content)
    // this.attachShadow({ mode: "open" })
    // this.shadowRoot.appendChild(template.content.cloneNode(true))
    // console.log(this.innerHTML, template.content)
    // this.shadowRoot.querySelector("div").innerHTML = this.innerHTML
  }

  // connectedCallback() {
  //   console.log("connected")
  //   this.render()
  //   this.bindEvents()
  // }

  // private
}

window.customElements.define("modal-window", ModalWindow)
