import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "backdrop" ]

  initialize() {
    document.addEventListener("dialog:open", (event) => {
      if (event.detail.id === `${this.element.id}_dialog_button`) {
        this.open()
      }
    })

    document.addEventListener("dialog:close", (event) => {
      if (event.detail.id === `${this.element.id}_dialog_button`) {
        this.close()
      }
    })
  }

  connect() {
    this.backdropTarget.addEventListener("click", (event) => {
      if (event.target === this.backdropTarget) {
        this.close()
      }
    })
  }

  open() {
    this.element.hidden = false
  }

  close() {
    this.element.hidden = true
  }
}