import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { type: String }
  
  connect() {
    this.show()  
  }

  show(event) {
    if (this.typeValue) {
      document.getElementById(this.typeValue).classList.remove('hidden');
    }
  }

}
