import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  submitFrame(event) {
    this.element.src = `${this.data.get('src')}?${this.buildQueryString()}`
    this.element.loaded.then(() => this.reset())
  }

  reset() {
    this.element.removeAttribute('src')
  }

  // private

    buildQueryString() {
      const queryStringObj = {}
      for (let element of this.element.querySelectorAll('input')) {
        queryStringObj[element.id] = element.value
      }
      return new URLSearchParams(queryStringObj).toString()
    }

}
