import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name:String }

  dispatch() {
    const customEvent = new CustomEvent(this.nameValue, { 
      bubbles: true,
      detail: { 
        id: this.element.id ? this.element.id : "",
      },
    })
    this.element.dispatchEvent(customEvent)
  }
}