import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  
  connect() {
    this.input = document.querySelector('input[type="file"]')
  }

  onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    Array.from(files).forEach(file => this.uploadFile(file))
  }
  
  uploadFile(file) {
    this.upload = new DirectUpload(file, this.uploadURL, this.token, this.attachmentName, this)

    this.upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.input.name
        this.input.insertAdjacentElement('afterend', hiddenField)
        this.insertMarkdownImage(blob.signed_id)
      }
    })
  }

  // private 

    insertMarkdownImage(blobID) {
      const markdown = `![](${blobID})`
      this.element.setRangeText(markdown)
    }

    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("progress",
        event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
      console.log(event.total)
      // Use event.loaded and event.total to update the progress bar
    }

    get uploadURL() {
      return this.input.dataset.directUploadUrl
    }

    get token() {
      return this.input.dataset.directUploadToken
    }

    get attachmentName() {
      return this.input.dataset.directUploadAttachmentName
    }
  
}
